export type Intepret = {
  name: string;
  imageUrl: string;
  day: string;
};

export const interprets: Intepret[] = [
  // Thursday
  {
    name: "Hoväda.sk",
    day: "Štvrtok",
    imageUrl: "/images/performing/thursday/hovada.jpg",
  },
  {
    name: "Iconito",
    day: "Štvrtok",
    imageUrl: "/images/performing/thursday/iconito.jpg",
  },
  {
    name: "Kali",
    day: "Štvrtok",
    imageUrl: "/images/performing/thursday/kali.jpg",
  },
  // Friday
  {
    name: "Cocotte Minute",
    day: "Piatok",
    imageUrl: "/images/performing/friday/cocotte-minute.jpeg",
  },
  {
    name: "Gladiátor",
    day: "Piatok",
    imageUrl: "/images/performing/friday/gladiator.jpg",
  },
  {
    name: "HEX",
    day: "Piatok",
    imageUrl: "/images/performing/friday/hex.jpg",
  },
  {
    name: "Kabát Revival",
    day: "Piatok",
    imageUrl: "/images/performing/friday/kabat.jpeg",
  },
  {
    name: "Zoči Voči",
    day: "Piatok",
    imageUrl: "/images/performing/friday/zoci-voci.jpg",
  },
  // Saturday
  {
    name: "Helenine Oči",
    day: "Sobota",
    imageUrl: "/images/performing/saturday/helenine-oci.jpeg",
  },
  {
    name: "Iné Kafe",
    day: "Sobota",
    imageUrl: "/images/performing/saturday/ine-kafe.jpg",
  },
  {
    name: "Katka Knechtová",
    day: "Sobota",
    imageUrl: "/images/performing/saturday/knechtova.jpg",
  },
];
